<template>
  <div>
    <NextSteps />
  </div>
</template>

<script>
import NextSteps from '@/components/NextSteps/NextSteps.vue';

export default {
  name: 'NextStepsPage',
  components: {
    NextSteps,
  },
};
</script>
